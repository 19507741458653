<template>
  <div class="title-bt" :class="{'title-bt-border': border != 'no'}">
    <div class="block" :style="`background-color:${blockColor};`"></div>
    <span v-if="titleType === '2'">
      <span
        v-for="(item, index) of title" :key="index"
        class="title-text cursor title-name-tab"
        :class="{'title-text-bor': index === 0, 'title-text':index > 0, 'title-text-on': titleIndex === index}"
        @click="titleClick(index)"
      >{{ item }}</span>
    </span>
    <span v-else class="title-name">{{title}}</span>
    <router-link v-if="src" class="more"  :to="{path:src, query:{}}">
      <span>查看更多>></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Title',
  data() {
    return {
      titleIndex: 0,
    };
  },
  props: {
    //  标题  必穿
    title: {
      default: '标题',
    },
    //  标题样式标识  必穿
    titleType: {
      type: String,
      default: '1',
    },
    // 是否有右边跳转更多
    src: {
      type: String,
      default: '',
    },
    // 是否显示下边边框
    border: {
      type: Boolean,
      default: true,
    },
    // 左边块 颜色
    blockColor: {
      type: String,
      default: '#0081ff',
    },
  },
  methods: {
    titleClick(index) {
      this.titleIndex = index;
      this.$emit('TitleFun', index);
    },
  },
};
</script>

<style scoped>
.title-text{
  margin-right: 20px;
  color: #888888;
  position: relative;
}
.title-text-bor::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 15px;
  top: 15px;
  right: -9px;
  background-color: #1E63B0;
}
.title-text-on{
  color: #1E63B0;
  border-bottom: 3px solid #1E63B0;
}
.title-text-l{
  margin-left: 10px;
}
.title-text-bor{
  /* border-right: 1px solid #1E63B0; */
}
.title-bt{
  height: 46px;
  padding-left: 15px;
  line-height: 46px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  font-weight: 600;
  color: #1E63B0;
}
.title-bt-border{
  border-bottom: 1px solid #CDD5DD;
}
/* .block{
  height: 20px;
  width: 5px;
  background-color: #e03997;
  border-radius: 2px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
} */
.more{
  height: 20px;
  line-height: 20px;
  padding: 0 15px;
  color: #1E63B0;
  position: absolute;
  font-size: 13px;
  top: 15px;
  right: 0px;
}
.title-btns{
  float: right;
}
.more {
  color: #666;
}
.more:hover {
  color: #1E63B0;
}
.title-name {
  box-sizing: border-box;
  height: 44px;
  line-height: 43px;
  display: inline-block;
  border-bottom: 3px solid #1E63B0;
}
.title-name-tab {
  box-sizing: border-box;
  height: 44px;
  line-height: 43px;
  display: inline-block;
}
</style>
