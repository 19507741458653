


































import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import Title from './Title.vue';

@Component({
  components: {
    Title,
  },
})
export default class ServeItem extends Vue {
  @Prop() private title!: string | string[];
  @Prop() private titleType!: string;
  @Prop() private url!: string;
  @Prop() private column!: string;
  @Prop() private list!: any[];
  @Prop() private list1!: any[];
  private newsList: any[] = [];
  private show:boolean=false
  @Watch('list')
  private wacthList(n: any, o: any) {
    this.newsList = n;
  }
  private created() {
   if (this.list.length > 0) {
     this.newsList = this.list;
   }
  }
  private TitleFun(ind: number): void {
    if (ind > 0) {
      this.newsList = this.list1;
    } else {
      this.newsList = this.list;
    }
  }
  private itemClick(item: string): void {
   const user = window.localStorage.getItem("userPermissions");
   let Permissions = user?.toString();
   if (Permissions === "1") {
        this.$emit('ServeItemFun', {
      item,
      column: this.column,
    });
    } else {
      this.show=true
    }
 
  }
  private AmendSubmits():void{
    this.$router.push('/apply/unit')
  }
  private resumeClear1():void{
    this.show=false
  }
}
