























































import { Component, Vue } from 'vue-property-decorator';
import ServeItem from '@c/serveItem/ServeItem.vue';
import { GetServeList, GetArticleList } from '@/http/api/ArticleApi';

@Component({
  components: {
    ServeItem,
  },
})
export default class Search extends Vue {
  private loading: boolean = false;
  private search: any = {
    text: '',
  };
  private cmSType: any = ['','cultivate','meeting','memberintroduction','memberintroduction','groupstandards','referencedata','consult'];
  private totalPage: number = 0; // 总页数
  private totalCount: number = 0; // 总条数
  private pageNo: number = 1; // 当前页数
  private pageSize: number = 10; // 每页几条

  private list: any[] = [];
  private created() {
    this.search.text = this.$route.query.text;
    this.getList((this.$route.query.text as string));
  }
  private listSearch() {
    if (!this.search.text) {
      this.$message.warning('请输入搜索关键字');
    } else {
      this.getList(this.search.text);
    }
  }
  private getList(keyword: string): void {
    this.loading = true;
    GetArticleList({ keyword, pageNo: 1, pageSize: 100 }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        const data = res.result;
        this.totalCount = data.total;
        this.totalPage = data.pages;
        this.pageNo = data.current;
        this.list = data.records;
      }
    });
  }
  private handleSizeChange(val: number): void {
    this.pageNo = 1;
    this.pageSize = val;
    if (!this.search.text) {
      this.$message.warning('请输入搜索关键字');
    } else {
      this.getList(this.search.text);
    }
  }
  private handleCurrentChange(val: number): void {
    this.pageNo = val;
    if (!this.search.text) {
      this.$message.warning('请输入搜索关键字');
    } else {
      this.getList(this.search.text);
    }
  }
  private ServeItemFun(obj: any) {
    // this.$router.go(-1);
    if (!obj.item.url) {
      const { href } = this.$router.resolve({
        path: '/article/detail',
        query: { articleId: obj.item.id,type: this.cmSType[obj.item.cmsType]}
      });
      window.open(href, '_blank');
    } else {
      window.open(obj.item.url, '_blank');
    }
  }
}
